<template>
  <div class="container">{{ t('172') }}</div>
</template>

<script>
import { t } from '../../languages';
import { defineComponent } from "@vue/runtime-core";
export default defineComponent({});
</script>

<style lang="less" scoped>
.container {
  width:100%;
  background: #001C31;
}
</style>